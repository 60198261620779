import React from 'react';
import './Publications.scss';


export default function Publications() {
    return (
        <div className="publication-table">
            <p>Still loading… please wait. ⏳📚 </p>
        </div>
    );
}
